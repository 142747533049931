<template>
  <v-row class="justify-center d-flex bg">
    <v-col cols="12">
      <v-carousel
        cycle
        show-arrows="hover"
        class="bg-color-carousel"
        :height="isBreakpointSmAndDown ? 'auto' : '45vh'"
        :progress="isMobile ? primary : false"
        :hide-delimiters="isMobile"
      >
        <v-carousel-item
          src="@/assets/anasayfa-geçiş-fotoları/ankara-enerji-zirvesi.png"
          lazy-src="@/assets/anasayfa-geçiş-fotoları/ankara-enerji-zirvesi.png"
          contain
        ></v-carousel-item>

        <v-carousel-item
          src="@/assets/anasayfa-geçiş-fotoları/advancing-women.jpg"
          lazy-src="@/assets/anasayfa-geçiş-fotoları/advancing-women.jpg"
          contain
        ></v-carousel-item>
      </v-carousel>
    </v-col>
    <v-col v-for="n in 12" :key="n" class="d-flex child-flex" cols="6" md="3">
      <v-img
        :src="require('@/assets/yk-fotisi' + n + '.jpeg')"
        :lazy-src="require('@/assets/yk-fotisi' + n + '.jpeg')"
        aspect-ratio="1"
        cover
        class="gallery-opacity"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey-lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "MainPage",
  computed: {
    isMobile() {
      return this.$vuetify.display.mobile;
    },
    isBreakpointSmAndDown() {
      return this.$vuetify.display.smAndDown;
    },
  },

  data: () => ({}),
};
</script>
<style scoped>
/* .gallery-opacity {
  opacity: 1;
} */

.bg-color-carousel {
  /* background-color: rgb(100, 100, 100); */
  background: linear-gradient(180deg, rgb(107, 102, 102), #c7672b);
}
</style>
