<template>
  <v-app class="overflow-hidden">
    <v-app-bar color="black lighten-3">
      <v-row align="center" class="mx-2">
        <v-col v-if="isBreakpointSmAndDown">
          <v-app-bar-nav-icon
            @click.stop="drawer = !drawer"
          ></v-app-bar-nav-icon>
        </v-col>

        <v-col v-else class="d-flex flex-nowrap">
          <v-btn to="/">Ana Sayfa</v-btn>
          <v-btn to="/etkinliklerimiz"> Etkinliklerimiz </v-btn>
          <!-- <v-menu
            transition="slide-x-reverse-transition"
            open-on-hover
            open-delay="100"
          >
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" to="/etkinliklerimiz">
                Etkinliklerimiz
              </v-btn>
            </template>
            <v-list bg-color="brown">
              <v-list-item
                v-for="event in events"
                :key="event.title"
                :to="event.route"
              >
                <v-list-item-title>{{ event.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu> -->

          <v-btn to="/hakkimizda">Hakkımızda</v-btn>
        </v-col>

        <v-col class="d-flex justify-space-around logo">
          <router-link to="/">
            <v-img
              class="mr-8"
              src="./assets/beyaz-yazılı-logo.png"
              max-heigth="10vh"
              min-width="8rem"
            ></v-img>
          </router-link>
        </v-col>

        <v-col
          class="justify-end d-flex flex-nowrap"
          v-if="!isBreakpointSmAndDown"
        >
          <v-btn to="/kayit-ol">Kayıt Ol!</v-btn>

          <v-btn to="/iletisim">İletişim</v-btn>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      temporary
      class="bg-green-darken-5"
      theme="dark"
    >
      <v-list density="compact" nav>
        <v-list-item v-for="item in items" :key="item.title" :to="item.route">
          <template v-slot:prepend>
            <v-icon>{{ item.icon }}</v-icon>
          </template>

          {{ item.title }}
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main class="bg">
      <v-container
        fluid
        class="d-flex align-center justify-center pa-1 fill-height"
      >
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app color="#FD681F">
      <v-row align="center" class="d-flex flex-nowrap justify-space-between"
        ><v-col v-show="!isBreakpointSmAndDown">
          <strong class="subheading"
            >Bizi takip etmeyi unutmayın!</strong
          > </v-col
        ><v-col class="d-flex justify-center pt-2 pb-0">
          <v-row no-gutters
            ><v-col cols="12" class="d-flex justify-center">
              <v-btn
                v-for="icon in icons"
                :key="icon.name"
                class="mx-4"
                elevation="5"
                dark
                icon
                :color="icon.color"
                :href="icon.url"
                target="_blank"
                :size="isBreakpointSmAndDown ? 'x-small' : 'small'"
              >
                <v-icon>
                  {{ icon.name }}
                </v-icon>
              </v-btn> </v-col
            ><v-col class="d-flex justify-center">
              <span class="text-caption"
                >Bu websitesinin sponsoru
                <a
                  class="text-decoration-none"
                  href="https://turkticaret.net"
                  target="_blank"
                  rel="noreferrer"
                  >Turkticaret.Net</a
                >'tir.
              </span>
            </v-col></v-row
          > </v-col
        ><v-col class="d-flex justify-end flex-nowrap" v-if="!isBreakpointXs">
          <p class="text-no-wrap">
            <span
              >2012 - {{ new Date().getFullYear() }} —

              <strong>{{
                !isBreakpointSmAndDown ? "Hacettepe Enerji Topluluğu" : "HET"
              }}</strong>
            </span>
          </p>
        </v-col></v-row
      >
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  computed: {
    isBreakpointSmAndDown() {
      return this.$vuetify.display.smAndDown;
    },
    isBreakpointXs() {
      return this.$vuetify.display.xs;
    },
    isBreakpointSm() {
      return this.$vuetify.display.sm;
    },
  },
  data: () => ({
    drawer: false,
    items: [
      {
        title: "Ana Sayfa",
        icon: "mdi-home",
        route: "/",
      },
      {
        title: "Etkinliklerimiz",
        icon: "mdi-calendar",
        route: "/etkinliklerimiz",
      },
      {
        title: "Hakkımızda",
        icon: "mdi-account-group",
        route: "/hakkimizda",
      },
      {
        title: "Kayıt Ol!",
        icon: "mdi-account-plus",
        route: "/kayit-ol",
      },
      {
        title: "İletişim",
        icon: "mdi-phone",
        route: "/iletisim",
      },
    ],
    events: [
      {
        title: "Advancing Women",
        route: "/advancing-women",
      },
      {
        title: "Enerji Zirvesi",
        route: "/enerji-zirvesi",
      },
      {
        title: "Teknik Etkinlikler",
        route: "/teknik-etkinlikler",
      },
      {
        title: "Sosyal Etkinlikler",
        route: "/sosyal-etkinlikler",
      },
    ],

    icons: [
      {
        name: "mdi-twitter",
        color: "blue",
        url: "https://twitter.com/hacettepehet",
      },
      {
        name: "mdi-linkedin",
        color: "blue-darken-3",
        url: "https://www.linkedin.com/company/enerji%CC%87-toplulu%C4%9Fu",
      },
      {
        name: "mdi-instagram",
        color: "pink",
        url: "https://www.instagram.com/hacettepehet/",
      },
      {
        name: "mdi-youtube",
        color: "red-darken-2",
        url: "https://www.youtube.com/channel/UCScgMb9g6BaNWMkpw1Ze_oA",
      },
      {
        name: "mdi-facebook",
        color: "indigo",
        url: "https://www.facebook.com/huenerji",
      },
    ],
  }),
};
</script>
<style>
.v-btn {
  z-index: 1;
  text-transform: none !important;
}
.logo {
  position: absolute;
}

.bg {
  background: linear-gradient(to right, #356051, #799a92);
}
</style>
