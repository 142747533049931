import { createRouter, createWebHashHistory } from "vue-router";
import MainPage from "../views/MainPage.vue";
const routes = [
  {
    path: "/",
    name: "main",
    component: MainPage,
  },
  {
    path: "/hakkimizda",
    name: "hakkimizda",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutPage.vue"),
  },
  {
    path: "/iletisim",
    name: "iletisim",
    component: () => import("../views/ContactPage.vue"),
  },
  {
    path: "/etkinliklerimiz",
    name: "etkinliklerimiz",
    component: () => import("../views/EventsPage.vue"),
  },
  {
    path: "/kayit-ol",
    name: "kayit-ol",
    component: () => import("../views/RegisterMember.vue"),
  },
  {
    path: "/advancing-women",
    name: "advancing-women",
    component: () => import("../views/Events/AdvancingWomen.vue"),
  },
  {
    path: "/ykbasvuru",
    name: "ApplyForManagementForm",
    component: () => import("../views/ApplyForManagementForm.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});

export default router;
